<template>
  <div>
    <!-- banner section Starts -->
    <section class="banner">
      <img
        :src="company_data.banner_image ?  company_data.banner_image : '../assets/images/homepage/banner-image.png'"
        alt="img"
        class="img-fluid banner-section-img"
      />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="card profile p-5">
              <div class="profile_card d-flex justify-content-center">
                <div class="pro_details text-center">
                  <img :src="(avatar) ? avatar : '../assets/images/home-essentials/user-image.png'" alt="">
                   
                  <h2>{{ first_name }} {{ last_name }}</h2>
                  <div class="border-bottom-1"></div>
                  <h3><span> Email: </span> {{ email }}</h3>

                  <button
                    class="btn btn_primary mt_40"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal6"
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade box_up"
      id="exampleModal6"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                id="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="getProfile"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <form
              class="p-4"
              @submit.prevent="editProfile"
              data-vv-scope="updateProfileForm"
            >
              <div class="file-upload for_cross">
                <div class="cross_box">
                <a v-if="avatar"
                  href="#"
                  class="modal_close"
                  @click="removeImage">
                  <img src="../assets/images/close_modal.png" />
                </a>
                </div>
                <div class="image-upload-wrap">              
                  <input
                    class="file-upload-input"
                    type="file"
                    @change="onFileChange"
                    accept="image/*"
                    v-validate="'mimes:image/jpeg,image/png,image/bmp,image/webp'" 
                    name="profile_image"
                  />
                  <div class="drag-text">                    
                    <h3>Drag and drop profile image</h3>
                  </div>
                   <div class="uploadedImages">
                     
                 <img :src="(avatar) ? avatar : '../assets/images/home-essentials/user-image.png'" alt="">
                </div>
                </div>
                <small class="error text-danger" v-show="errors.has('updateProfileForm.profile_image')" >
                  Profile image has invalid extension
                </small>
               
              </div>
              <div class="form-group mb_50">
                <!-- <label class="font_size_24 mb_20 font_bold">Name</label> -->
                <input
                  type="text"
                  placeholder="First name"
                  class="form-control"
                  v-model="first_name"
                  name="first_name"
                  v-validate="'required|max:20'"
                />
                <small
                    class="error text-danger"
                    v-show="errors.has('updateProfileForm.first_name')"
                  >
                    {{ errors.first("updateProfileForm.first_name") }}
                  </small>
              </div>
              <div class="form-group mb_50">
                <!-- <label class="font_size_24 mb_20 font_bold">Name</label> -->
                <input
                  type="text"
                  placeholder="Last name"
                  class=" form-control"
                  v-model="last_name"
                  name="Last Name"
                  v-validate="'required|max:20'"
                />
                <small
                    class="error text-danger"
                    v-show="errors.has('updateProfileForm.Last Name')"
                  >
                    {{ errors.first("updateProfileForm.Last Name") }}
                  </small>
              </div>
              <div class="form-group">
                <!-- <label class="font_size_24 mb_20 font_bold">Email</label> -->
                <input
                  type="text"
                  placeholder="Email address"
                  class="mb_50 form-control opacity-50"
                  v-model="email"
                  name="email"
                  v-validate="'required|email'"
                disabled/>
                <small
                    class="error text-danger"
                    v-show="errors.has('updateProfileForm.email')"
                  >
                    {{ errors.first("updateProfileForm.email") }}
                  </small>
              </div>
              <div class="mt_68 text-center">
                <button type="submit" class="btn btn_primary">
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner section Ends -->
  </div>
</template>
<script>
import commonFunction from "../mixin/commonFunction";
import "bootstrap/dist/css/bootstrap.css";

export default {
  name: "profile",
  mixins: [commonFunction],
  data() {
    return {
    company_data : {
            banner_image: require('@/assets/images/homepage/banner-image.png'),
            logo: require('@/assets/images/logo.png'),
            work_area:null,
            company_code:null
         },
      first_name: null,
      last_name: null,
      email: null,
      avatar: null,
      base_url: null,
      isLoading:false,
    };
  },
  mounted() { 
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.email = localStorage.getItem("employeeEmail");
    this.base_url = process.env.BASE_URL;
    this.getProfile();
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    let company_data = data.client;
    if(company_data == null){
        company_data = [];
      }
      if(company_data.length == 0 || company_data.length === 'undefined'){ 
        // console.log('no company values home')
        this.company_data.work_area = '["Office","Home","Anywhere","Lab"]';
      }else{
        this.company_data.banner_image = company_data.banner_image;
         this.company_data.logo = company_data.logo;
         this.company_data.work_area = company_data.work_area;
         this.company_data.company_code = company_data.company_code;
        //  console.log(this.company_data);
      }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader(); 

      reader.onload = (e) => { 
        this.avatar = e.target.result;        
      };

      reader.readAsDataURL(file);
    },
    removeImage(){
      this.avatar = null;
    },
    editProfile() {
      if (localStorage.getItem("userToken")) {
        this.$validator.validateAll("updateProfileForm").then((isValid) => {
          if (isValid) {
            this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            var request_data = {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              avatar: this.avatar,
            };
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/profile-update",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
              },
              // data: {
              //   first_name: this.first_name,
              //   last_name: this.last_name, 
              //   email: this.email, 
              //   avatar: this.avatar, 
              // },
              data: { request_data: this.encodeAPIRequest(request_data) },
            };
            this
              .axios(config)
              .then((res) => {
                this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                this.$toast.success(res.data.message, {
                  position: "top-right",
                  duration: 5000,
                });
                this.closeModal();
                // this.$router.push({ name: "profile" }).catch(() => {}); 
                // this.$router.go(); 
              })
              .catch((err) => {
                if (err.response.status == 422) {
                  var err1 = '';
                  for (let x in err.response.data.error) {
                    err1 += err.response.data.error[x];
                    err1 += '<br>';
                  }
                  this.$toast.error(err1, {
                    position: "top-right",
                    duration: 5000,
                  });
                } else {
                  this.$toast.error(err.response.data.error, {
                    position: "top-right",
                    duration: 5000,
                  });
                }
                this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                this.closeModal();
              });
          }
        });
      } else {
        this.tokenExpired();
      }
    },
    getProfile() {
      if (localStorage.getItem("userToken")) {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "get", 
          url: process.env.VUE_APP_API_URL + "/api/get-profile",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        }; 
        this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = this.decodeAPIResponse(data.data);
          this.first_name = decodedJson.user.first_name;
          this.last_name = decodedJson.user.last_name;
          this.email = decodedJson.user.email;
          this.avatar = decodedJson.user.avatar;
          if(this.company_data.company_code.toLowerCase()=='airbnb')
          this.$parent.$refs.airbnbheadercompnent.avatar = decodedJson.user.avatar;
          else
          this.$parent.$refs.headercompnent.avatar = decodedJson.user.avatar;

          window.localStorage.setItem("user_profile",decodedJson.user.avatar);  
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(( {response} ) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.data.type == false) {
            this.$router.push({ name: "login" });
          }
        });
      } else{
        this.tokenExpired();
      }
    },
    closeModal() {
      document.getElementById('modal_close').click();
    },
  },
};
</script>